import cn from 'classnames'
import React from 'react'

//
import { IconNew, ButtonNew, SimpleContentBlock } from '..'
import { LocationContext, SiteDataContext } from '../../components'
import { getUrl } from '../../utils/urls'
import { BUTTON_VARIANT } from '../ButtonNew/ButtonNew'
import SectionHeader, { ALIGN, HEADING_TYPE } from '../SectionHeader'
import { NavLink } from '../helpers'
import { useWindowWidth, withAnchor } from '../hooks'

const VIEWPORT_9 = 992

const IntegrationDetail = ({
  preface = '',
  title = '',
  contentList,
  descriptionTokens = {},
  items = [],
  children,
  anchor,
  primaryAction
}) => {
  const windowWidth = useWindowWidth()
  const { isTextRtl } = React.useContext(SiteDataContext)
  const location = React.useContext(LocationContext)
  const { slugPrefix } = location

  const doesContentListExists = contentList && contentList.length > 0

  const itemsToShow =
    contentList.length > 0
      ? items.map(({ id, title, url, logo }) => (
          <NavLink
            to={url}
            key={id}
            className="l-grid l-grid--align:center l-grid__col:6/12 l-grid__col:3/12@at:viewport-4"
          >
            <img
              loading="lazy"
              key={id}
              src={logo}
              title={title}
              alt={title}
              width="72"
              height="72"
            />
          </NavLink>
        ))
      : items.map(({ id, title, type, shortDescription, url, logo }) => (
          <NavLink
            to={url}
            key={id}
            className="l-grid l-grid--align:center l-grid__col:6/12 l-grid__col:3/12@at:viewport-4 c-integration-listing__logo-container"
          >
            <img
              loading="lazy"
              key={id}
              src={logo}
              title={title}
              alt={title}
              width="72"
              height="72"
            />
            <div className="c-integration-listing__logo-container__title">
              <strong>{title}</strong>
              <span>{shortDescription || type}</span>
            </div>
          </NavLink>
        ))

  return (
    <section
      className="l-section l-section--force-padding c-integration-listing"
      dir={isTextRtl ? 'rtl' : 'ltr'}
    >
      <div className="l-container:12/12 l-container--default-spacing">
        <div
          className={cn('l-grid l-grid--default-spacing', {
            'l-grid--spacing:20': windowWidth > VIEWPORT_9
          })}
        >
          <div
            className={cn(
              doesContentListExists
                ? 'l-grid__col:12/12 l-grid__col:6/12@at:viewport-9'
                : 'l-grid__col:12/12@at:viewport-9'
            )}
          >
            <div className="l-section__header l-container:8/12">
              <SectionHeader
                anchor={anchor}
                title={title}
                eyebrow={!contentList.length > 0 ? preface : null}
                text={contentList.length > 0 ? preface : null}
                textTokens={descriptionTokens}
                headingType={HEADING_TYPE.h2}
                align={doesContentListExists ? ALIGN.left : ALIGN.center}
              />
            </div>

            {items.length > 0 && (
              <div className="l-section__content c-integration-listing__content">
                <hr />
                <div className="c-integration-listing__logos l-grid l-grid--default-spacing ">
                  {itemsToShow}
                </div>
                <hr />
              </div>
            )}
            {primaryAction ? (
              <div
                className={cn('c-integration-listing__action-container', {
                  'u-text-align:center': !doesContentListExists
                })}
              >
                <ButtonNew variant={BUTTON_VARIANT.GREEN} url={getUrl(primaryAction, slugPrefix)}>
                  {primaryAction.title} <IconNew name={'tabler-arrow-narrow-right'} />{' '}
                </ButtonNew>
                {primaryAction.subtext ? <p>{primaryAction.subtext}</p> : null}
              </div>
            ) : null}

            {children ? <div className="l-section__footer">{children}</div> : null}
          </div>
          {doesContentListExists ? (
            <div className="l-grid__col:6/12@at:viewport-9 c-integration-listing__list">
              {contentList.map(({ id, title, icon, description }) => (
                <SimpleContentBlock
                  key={id}
                  classes="c-integration-listing__list-item"
                  icon={icon}
                  title={title}
                  bulletListColor={'white'}
                  description={description}
                />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default withAnchor(IntegrationDetail)
