import React, { useContext } from 'react'

//
import { ButtonNew, OfficesBlock } from '../../../storybook'
import { BUTTON_VARIANT } from '../../../storybook/ButtonNew/ButtonNew'
import { THEME } from '../../../utils/common'
import { ImageFluid } from '../../../utils/image'
import { getUrl } from '../../../utils/urls'
import { LocationContext } from '../../page-wrapper'

const BlockOffices = ({ data: { title, preface, offices, action, theme } }) => {
  const location = useContext(LocationContext)
  const { slugPrefix } = location
  const cards = offices?.map(({ id, title, description: address, image, action: cardAction }) => ({
    id,
    title: title || '',
    description: address || '',
    image: image && <ImageFluid image={image} />,
    action: cardAction && (
      <ButtonNew
        classes="u-margin-top:24"
        size={'md'}
        variant={BUTTON_VARIANT.GREEN}
        url={getUrl(cardAction, slugPrefix)}
      >
        {cardAction.title}
      </ButtonNew>
    )
  }))

  const cta = action ? (
    <ButtonNew
      classes={'u-margin-top:32'}
      variant={theme === THEME.dark ? BUTTON_VARIANT.SECONDARY : BUTTON_VARIANT.PRIMARY}
      url={getUrl(action, slugPrefix)}
    >
      {action.title || ''}
    </ButtonNew>
  ) : null

  return (
    <OfficesBlock
      title={title || ''}
      eyebrow={preface || ''}
      actionSlot={cta}
      items={cards}
      theme={theme}
    />
  )
}

export default BlockOffices
