import cn from 'classnames'
import React from 'react'

//
import { SiteDataContext } from '../../components'
import { MicroCopyContext } from '../../components/page-wrapper'
import { getMicroCopy } from '../../utils/microcopy'
import IconNew from '../IconNew'
import IntegrationLabel from '../IntegrationLabel'
import NavLink from '../helpers/nav-link'
import { withAnchor } from '../hooks'

import { IntegrationTypes } from './Integration.const'

const Integration = ({
  title = '',
  type = '',
  url,
  logo = '',
  label = '',
  labelType = '',
  size = IntegrationTypes.small,
  classes = '',
  numberOfUsers = '',
  shortDescription = null
}) => {
  const microCopyData = React.useContext(MicroCopyContext)
  const { isTextRtl } = React.useContext(SiteDataContext)
  const linkTitle = `${title} - ${type}`

  const usersLabel = getMicroCopy({
    key: `global.users`,
    fallback: 'users',
    data: microCopyData
  })?.toLowerCase()

  if (size === IntegrationTypes.largeVariation) {
    return (
      <NavLink
        to={url}
        className={cn('c-integration c-integration--large-variation ', classes, {
          'c-integration--rtl': isTextRtl
        })}
      >
        <div className="c-integration__header">
          <div className="c-integration__logo">
            <img
              loading="lazy"
              className="c-integration__image"
              src={typeof logo === 'object' ? logo?.url : logo}
              alt={typeof logo === 'object' ? logo?.title : linkTitle}
              title={typeof logo === 'object' ? logo?.title : linkTitle}
              width="40"
              height="40"
            />
          </div>

          <h3 className="c-integration__title">{title}</h3>
        </div>
        <div className="c-integration__body">
          <p className="c-integration__type">{shortDescription || type}</p>
          {numberOfUsers ? (
            <p className="c-integration__users">
              <IconNew name={'ti-users'} classes={'c-integration__users__icon'} size={12} />
              <span>{`${numberOfUsers?.toUpperCase()} ${usersLabel}`}</span>
            </p>
          ) : null}
        </div>
        {label && (
          <div className="c-integration__label">
            <IntegrationLabel text={label} type={labelType}>
              {label}
            </IntegrationLabel>
          </div>
        )}
      </NavLink>
    )
  }

  return (
    <NavLink
      to={url}
      className={cn('c-integration', classes, {
        [`c-integration--${size}`]: size,
        'c-integration--rtl': isTextRtl
      })}
    >
      <div className="c-integration__logo">
        <img
          loading="lazy"
          className="c-integration__image"
          src={typeof logo === 'object' ? logo?.url : logo}
          alt={typeof logo === 'object' ? logo?.title : linkTitle}
          title={typeof logo === 'object' ? logo?.title : linkTitle}
          width="40"
          height="40"
        />
      </div>

      {size !== IntegrationTypes.small && (
        <div className="c-integration__body">
          <h3 className="c-integration__title">{title}</h3>
          <p className="c-integration__type">{type}</p>
        </div>
      )}

      {label && (
        <div className="c-integration__label">
          <IntegrationLabel text={label} type={labelType}>
            {label}
          </IntegrationLabel>
        </div>
      )}
    </NavLink>
  )
}

export default withAnchor(Integration)
